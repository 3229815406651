import { 
  styled,
  InputBase,
  FormControl,
  InputLabel
 } from '@mui/material'
import Alert from '@mui/material/Alert'

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: '10px',
        position: 'relative',
        border: 'none',
        fontSize: '16px',
        width: '22.5em',
        height: '1em',
        padding: '1em',
        color: '#000',
        background: '#F0F0F0',
        fontFamily: 'DMSans'
    },
    '@media (max-width: 768px)': {
      '& .MuiInputBase-input': {
        width: '20rem'
      }
    },
    '@media (max-width: 390px)': {
      '& .MuiInputBase-input': {
        width: '10rem',
        
      }
    }
}))

export default function AtodTextInput(props) {

  const handleInput = e => {
    props.textInput(e.target.value)
  }
  
    return (
      <FormControl style={{ marginTop: '1.1em', width: props.width, marginBottom: props.marginBottom}}>
        <InputLabel sx={{ fontFamily: 'DMSans' }}>
            {props.label}
        </InputLabel>
  
        <BootstrapInput 
          type={props.type ?? 'text'} 
          placeholder={props.placeholder} 
          onChange={handleInput}
        >
          {props.children}
        </BootstrapInput>
        {props.errorMessage && <Alert severity='error' sx={{padding: '0 1em'}}>{props.errorMessage}</Alert>}
      </FormControl>
    )
}
