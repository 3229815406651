import axios from "axios"

export const  baseURL = 'https://www.atod.link/'

const Instance = axios.create({
    baseURL: baseURL,
    timeout: 12000,
})

Instance.interceptors.request.use(async (req) => {
    const token = localStorage.getItem('ATODTeacherToken')
    if (!token) return req
    req.headers.Authorization = `Bearer ${token}`
    return req
})

export default Instance