import { useState } from 'react'
import './Auth.css'
import { Link, useHistory } from 'react-router-dom'
import Instance from '../../utils/axios'
import useTeacherState from '../../utils/useTeacherContext'
import { loginEmail } from '../../utils/validation'
import AtodButton from '../../components/AtodButton'
import AtodTextInput from '../../components/AtodTextInput'
import Alert from '@mui/material/Alert'
import MainImg from '../../assets/Images/Main_Img.png'
import AtodLogo from '../../assets/Images/AtodLogo.png'
import AtodImg from '../../components/AtodImg/AtodImg'

const Login = () => {

    const { teacherDispatch } = useTeacherState()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isLoginLoading, setIsLoginLoading] = useState(false)
    const [validationMessage, setValidationMessage] = useState('')
    const history = useHistory()

    const loginUser = async () => {
        const message = loginEmail(email)
        message && setValidationMessage(message)
        setIsLoginLoading(true)
        try {
            const emailExist = await Instance.get(`teacher/whetherExist?email=${email}`, { email })
            if (emailExist.status !== 200) return

            const result = await Instance.post('teacher/login', {
                email,
                password
            })

            localStorage.setItem('ATODTeacherToken', result.data.JWT.token)
            teacherDispatch({ type: 'setTeacher', data: { ...result.data.teacher, dance: result.data.dances } })
            setIsLoginLoading(false)
            history.push({ pathname: '/dashboard' })

        } catch (error) {
            console.log(error)
            console.log('An unknown error has occured')
            setValidationMessage('Either email or password is incorrect')
        }
        setIsLoginLoading(false)
    }

    return (
        <div className='min_width_wrapper'>
            <AtodImg img={MainImg}  className='img_view_login' />
            <div className='right_container'>
                <div className='centralizing'>
                    <img src={AtodLogo} alt='ATOD Logo' style={{ width: '120px', height: 'auto' }} />
                    <div className='main_title'>Welcome to ATOD@Work</div>
                    <p className='section_instruction'>Enter your email and password to sign in to your ATOD@Work account.</p>
                    {validationMessage ? <Alert severity='error'>{validationMessage}</Alert> : ""}
                    <AtodTextInput value={email} type='email' label='Email' placeholder='sample@atod.net.au' textInput={setEmail} />
                    <AtodTextInput value={password} type='password' label='Password' placeholder='Enter' textInput={setPassword} />
                    <Link to={'retrive_password'} className='retrieve_pwd'>Retrieve password here</Link>
                    <AtodButton
                        text='Sign In'
                        margin='20px 0'
                        onClick={loginUser}
                        isLoading={isLoginLoading}
                    />
                    <div className='border_style'></div>
                    <div className='main_title'>Sign up</div>
                    <p className='section_instruction'>Don't have an ATOD@Work account? Sign up now to get started.</p>
                    <div className='trial_box'>
                        <div className='main_title'>14 Day Free Trial!</div>
                        <p className='section_instruction'>Sign up and get a 14 day free trial to ATOD@Work.</p>
                    </div>
                    <Link to={'signup'}>
                        <AtodButton
                            text='Sign Up'
                            bg='#FFFFFF'
                            hc='#FFFFFF'
                            color='#005FC5'
                            margin='20px 0'
                            border='1px solid #005FC5'
                        />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Login